import React from 'react';

import Layout from '../../Layout/component';

const GamesPage = () => (
  <Layout routeSlug="Games">
    <h1>Coming Soon</h1>
  </Layout>
);

export default GamesPage;
